<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div v-if="isSidebarActive">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ data.id ? 'Editar' : 'Nuevo' }} Comercio
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Image -->
            <div class="d-flex justify-content-center">
              <b-img
                ref="refPreviewEl"
                :src="data.file"
                height="150"
                width="150"
                class="rounded mb-1 mb-md-0 cursor-pointer"
                @click="$refs.refInputEl.$refs.input.click()"
              />
            </div>
            <b-form-group
              v-if="data.id"
              label="Imagen"
              label-for="file"
            >
              <b-form-file
                ref="refInputEl"
                v-model="data.fileInput"
                accept=".jpg, .png, .webp"
                placeholder="Elija el archivo"
                @input="onChangeFile($event)"
              />
            </b-form-group>
            <validation-provider
              v-else
              #default="validationContext"
              name="Imagen"
              rules="required"
            >
              <b-form-group
                label="Imagen"
                label-for="file"
              >
                <b-form-file
                  ref="refInputEl"
                  v-model="data.fileInput"
                  accept=".jpg, .png, .webp"
                  placeholder="Elija el archivo"
                  @input="onChangeFile($event)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="data.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Descripción"
              rules="required"
            >
              <b-form-group
                label="Descripción"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="data.description"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider
              name="Activo"
            >
              <b-form-group
                label="Activo"
                label-for="statusCheck"
              >
                <b-form-checkbox
                  id="statusCheck"
                  v-model="data.status"
                  name="statusCheck"
                >
                  {{ data.status ? 'Si' : 'No' }}
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="loading"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <template v-if="loading">
                  <b-spinner
                    variant="light"
                    class="mr-75"
                    small
                  />
                  <span>{{ data.id ? 'Actualizando' : 'Creando' }}</span>
                </template>
                <span v-else>{{ data.id ? 'Actualizar' : 'Crear' }}</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="loading"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BSpinner,
  BFormFile,
  BImg,
  // BTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import { useFileToBase64 } from '@core/comp-functions/forms/form-utils'
// import countriesList from '@/@fake-db/data/other/countries'
// import store from '@/store'
// import { typesCashiers } from '@core/constants'
// import useCountries from '../countries/useCountries'
import useCommerce from './useCommerce'
// import useCurrencies from '../currencies/useCurrencies'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BSpinner,
    BFormFile,
    BImg,
    // BTooltip,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countriesList,
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val && this.item) {
        this.data = {
          id: this.item.id,
          file: this.item.logo,
          fileInput: null,
          name: this.item.name,
          description: this.item.description,
          status: this.item.status,
        }
      } else {
        this.data = {
          id: null,
          file: null,
          fileInput: null,
          name: '',
          description: '',
          status: true,
        }
        this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      file: null,
      fileInput: null,
      name: '',
      description: '',
      status: true,
    }

    const data = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(blankData))
    }

    // const types = ref(typesCashiers)
    const loading = ref(false)
    const { store, update } = useCommerce()
    // const { fetchCountriesSelector } = useCountries()
    // const { fetchCurrenciesSelector } = useCurrencies()
    // const countries = ref([])
    // const currencies = ref([])

    // const onKeydown = e => {
    //   // [Backspace, Tab, Enter, ArrowLeft, ArrowRight]
    //   const keys = [8, 9, 13, 37, 39]

    //   if ((e.keyCode < 48 || e.keyCode > 57) && !keys.includes(e.keyCode)) {
    //     e.preventDefault()
    //     e.stopPropagation()
    //   }
    // }

    // onMounted(async () => {
    //   const countryList = await fetchCountriesSelector()
    //   const currenciesList = await fetchCurrenciesSelector()

    //   countries.value = countryList
    //   currencies.value = currenciesList
    // })

    const onSubmit = async () => {
      loading.value = true

      const form = { ...data.value }

      delete form.fileInput

      if (form.id && form.file.startsWith('http')) {
        form.file = ''
      }

      try {
        form.logo = form.file

        if (form.id) {
          await update(form.id, form)
        } else {
          await store(form)
        }

        emit('refetchData')
        emit('update:is-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onChangeFile = async event => {
      const base64 = await useFileToBase64(event)

      data.value.file = base64
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      data,
      loading,
      onSubmit,

      // types,
      // countries,
      // currencies,
      refFormObserver,
      getValidationState,
      resetForm,
      onChangeFile,

      // onKeydown,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
